<template>
    <v-container class="">
        <confirmation-dialog
            :actions="actions"
            :dialog="dialogs.confirmdialog"
            @confirm="delete_task(dialogs.confirmdialog.task)"
        />
        <finish-task-dialog
            :dialog="dialogs.finishdialog"
            @add="actions.loading || submit_finish($event)"
        />
        <cancel-task-dialog
            :dialog="dialogs.canceldialog"
            @submit_cancel="actions.loading || submit_cancel($event)"
        />
        <!-- <create-side-task-dialog
      :dialog="dialogs.create_side_task_dialog"
      @add="actions.loading || create_side_task($event)"
    /> -->
        <v-row class="">
            <v-col cols="12" class="px-0 pb-0">
                <slide-buttons v-model="day" :items="items" />
            </v-col>
        </v-row>
        <v-row class="">
            <v-col cols="12" class="py-0">
                <v-container class="py-0">
                    <v-row
                        class="px-4 py-8"
                        v-for="(task, index) in chosen_day_tasks"
                        :key="index"
                    >
                        <task-view
                            :color="get_color(task)"
                            :actions="actions"
                            :icon="get_icon(task)"
                            :events="task_view_events(task)"
                            no_geolocation
                            :task="task"
                            @start="start(task)"
                            @finish="finish(task)"
                            @cancel="cancel(task)"
                            @delete="
                                dialogs.confirmdialog.task = task;
                                dialogs.confirmdialog.show = true;
                            "
                        />
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Api from "@/api/api/index.js";
import { Geolocation } from "@capacitor/geolocation";
export default {
    data: () => ({
        work_days: [],
        dialogs: {
            finishdialog: {
                finishing: true,
                title: "Finish Task",
                no_activator: true,
                show: false,
                details: null,
            },
            canceldialog: {
                details: null,
                show: false,
            },
            create_side_task_dialog: {
                title: "Add Side Task",
                no_activator: true,
                show: false,
            },
            confirmdialog: {
                text: {
                    title: "Delete this task?",
                    text: "Are you sure you want to delete this task? on confirmation this task will effective emmediatly will be deleted.",
                    cancel: "Cancel",
                    yes: "Yes",
                },
                task: null,
                show: false,
            },
        },
        actions: {
            loading: false,
        },
        task_color_map: {
            1: "blue lighten-4",
            2: "yellow lighten-3",
            3: "light-green accent-1",
            4: "deep-orange lighten-4",
            5: "blue-grey lighten-4",
        },
        day: null,
        loading: false,
    }),
    props: {
        days: null,
        bundle: null,
    },
    watch: {
        days: {
            deep: true,
            handler(val) {
                if (!this.day) {
                    this.day = val[0].date;
                }
            },
        },
    },
    async mounted() {
        try {
            const work_days = (
                await Api.post("task/fetch/work_days", null, {
                    sinceMins: 30,
                    now: false,
                })
            ).data.work_days;
            this.$set(this, "work_days", work_days);
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        get_geolocation() {
            return new Promise(async (resolve, reject) => {
                try {
                    const pos = await Geolocation.getCurrentPosition();
                    if (!pos?.coords?.latitude) {
                        throw { error: { msg: "Location is required" } };
                    }
                    resolve(pos);
                } catch (error) {
                    reject({
                        error: {
                            msg: "Location is required",
                        },
                    });
                }
            });
        },

        get_color(task) {
            console.log(
                task.task_designated_date,
                task.status,
                this.task_color_map[task.status]
            );
            return this.task_color_map[task.status] || "white";
        },

        get_icon(task) {
            return `mdi-roman-numeral-${task.type}`;
        },

        async delete_task(task) {
            if (task.type != 2) {
                return;
            }
            this.actions.loading = true;
            try {
                const task_details = {
                    task_id: task.task_id,
                    bundle_id: task.bundle_id,
                };
                const result = await Api.post(
                    "/task/update/delete_side_task",
                    task_details
                );
                this.$emit("refresh");
            } catch (err) {
                console.log("positioning error ", err);
                let notification = {
                    msg:
                        err.response?.data?.error?.msg ||
                        err.msg ||
                        "Error occured",
                    color: "error",
                };
                await this.$store.dispatch("user/notify", notification);
            }
            this.actions.loading = false;
        },

        async start(task) {
            this.actions.loading = true;
            try {
                const position = await this.get_geolocation();

                const start_details = {
                    start_details: {
                        position: {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            accuracy: position.coords.accuracy,
                        },
                    },
                    task_id: task.task_id,
                    bundle_id: task.bundle_id,
                };
                const result = await Api.post(
                    "/task/update/start_task",
                    start_details
                );
                this.$emit("refresh");
            } catch (err) {
                console.log("positioning error ", err);
                let notification = {
                    msg:
                        err.response?.data?.error?.msg ||
                        err.msg ||
                        "Please allow the app to get your position to start the task",
                    color: "error",
                };
                await this.$store.dispatch("user/notify", notification);
            }
            this.actions.loading = false;
        },

        async finish(task) {
            this.actions.loading = true;
            try {
                const position = await this.get_geolocation();
                const finish_details = {
                    finish_details: {
                        position: {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            accuracy: position.coords.accuracy,
                        },
                    },
                    task_id: task.task_id,
                    bundle_id: task.bundle_id,
                };
                this.dialogs.finishdialog.details = finish_details;
                await this.$set(this.dialogs.finishdialog, "task", task);
                this.dialogs.finishdialog.show = true;
            } catch (err) {
                console.log("positioning error ", err);
                let notification = {
                    msg:
                        err.response?.data?.error?.msg ||
                        err.msg ||
                        "Please allow the app to get your position to start the task",
                    color: "error",
                };
                await this.$store.dispatch("user/notify", notification);
            }
            this.actions.loading = false;
        },
        async submit_finish(info) {
            try {
                this.dialogs.finishdialog.details.finish_details = {
                    ...this.dialogs.finishdialog.details.finish_details,
                    ...info,
                };
                await Api.post(
                    "/task/update/finish_task",
                    this.dialogs.finishdialog.details
                );
                await this.$emit("refresh");
            } catch (err) {
                console.log("positioning error ", err);
                let notification = {
                    msg:
                        err.response?.data?.error?.msg ||
                        err.msg ||
                        "Please allow the app to get your position to start the task",
                    color: "error",
                };
                await this.$store.dispatch("user/notify", notification);
            }
        },

        async cancel(task) {
            this.actions.loading = true;
            try {
                const position = await this.get_geolocation();
                const finish_details = {
                    finish_details: {
                        position: {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            accuracy: position.coords.accuracy,
                        },
                    },
                    task_id: task.task_id,
                    bundle_id: task.bundle_id,
                };
                this.dialogs.canceldialog.details = finish_details;
                this.dialogs.canceldialog.show = true;
            } catch (err) {
                console.log("positioning error ", err);
                let notification = {
                    msg:
                        err.response?.data?.error?.msg ||
                        err.msg ||
                        "Please allow the app to get your position to start the task",
                    color: "error",
                };
                await this.$store.dispatch("user/notify", notification);
            }
            this.actions.loading = false;
        },
        async submit_cancel(info) {
            console.log(info);
            try {
                this.dialogs.canceldialog.details.finish_details = {
                    ...this.dialogs.canceldialog.details.finish_details,
                    ...info,
                };
                console.log(this.dialogs.canceldialog.details.finish_details);
                await Api.post(
                    "/task/update/cancel_task",
                    this.dialogs.canceldialog.details
                );
                await this.$emit("refresh");
            } catch (err) {
                console.log("positioning error ", err);
                let notification = {
                    msg:
                        err.response?.data?.error?.msg ||
                        err.msg ||
                        "Please allow the app to get your position to start the task",
                    color: "error",
                };
                await this.$store.dispatch("user/notify", notification);
            }
        },

        async create_side_task(task) {
            try {
                await Api.post("/task/update/create_side_task", {
                    task: task,
                    timestamp: this.days?.filter((el) => el.date == this.day)[0]
                        .timestamp,
                    bundle_id: this.bundle?.bundle_id,
                });
                let notification = {
                    msg: "Side Task Created",
                    color: "success",
                };
                await this.$store.dispatch("user/notify", notification);
                this.$emit("refresh");
            } catch (err) {
                console.log("positioning error ", err);
                let notification = {
                    msg:
                        err.response?.data?.error?.msg ||
                        err.msg ||
                        "Error, Please Try Again Later",
                    color: "error",
                };
                await this.$store.dispatch("user/notify", notification);
            }
        },

        task_view_events(task) {
            if (task.status == 1) {
                const returned = [
                    {
                        event: "start",
                        class: "primary--text",
                        title: "START",
                    },
                    {
                        event: "cancel",
                        class: "error--text",
                        title: "CANCEL TASK",
                    },
                ];
                if (task.type == 2) {
                    returned.push({
                        event: "delete",
                        class: "error--text",
                        title: "Delete",
                    });
                }
                return returned;
            } else if (task.status == 2) {
                return [
                    {
                        event: "finish",
                        class: "primary--text",
                        title: "FINISH",
                    },
                    {
                        event: "cancel",
                        class: "error--text",
                        title: "CANCEL TASK",
                    },
                ];
            } else {
                return [];
            }
        },

        english_to_arabic_day_of_week_index(english_index) {
            if (english_index == 6) {
                return 0;
            } else {
                return english_index + 1;
            }
        },
        ti: (n) => {
            console.log(n);
            if (n == 6) {
                return 0;
            } else {
                return n + 1;
            }
        },
    },
    computed: {
        chosen_day_tasks() {
            const returned =
                this.days?.filter((el) => el.date == this.day)[0]?.tasks || [];
            return returned;
        },
        items() {
            const ti = this.ti;
            return this.days
                ?.sort((a, b) => {
                    return ti(a.dayofweek_index) < ti(b.dayofweek_index)
                        ? -1
                        : 1;
                })
                .filter((day) => {
                    return this.work_days.includes(day.dayofweek_index);
                })
                .map((el) => ({ title: el.date, subtitle: el.dayofweek }));
        },
    },
    components: {
        ConfirmationDialog: () =>
            import(
                /* webpackChunkName: "confirm_dialog" */
                "./components/confirm_dialog.vue"
            ),
        SlideButtons: () =>
            import(
                /* webpackChunkName: "workday_no_tasks" */
                "./components/slide_buttons.vue"
            ),
        TaskView: () =>
            import(
                /* webpackChunkName: "task" */
                "./components/task.vue"
            ),
        CancelTaskDialog: () =>
            import(
                /* webpackChunkName: "task" */
                "./components/CancelTaskDialog.vue"
            ),
        FinishTaskDialog: () =>
            import(
                /* webpackChunkName: "task" */
                "./components/add_task.vue"
            ),
        CreateSideTaskDialog: () =>
            import(
                /* webpackChunkName: "task" */
                "./components/add_task.vue"
            ),
    },
};
</script>

<style>
</style>